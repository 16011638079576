/*
  Icon Font: fontcustom
*/

@font-face {
  font-family: "fontcustom";
  src: url("./fontcustom_f45e01a16989f9fc802eebb7307bdd89.woff") format("woff"),
       url("./fontcustom_f45e01a16989f9fc802eebb7307bdd89.ttf") format("truetype"),
       url("./fontcustom_f45e01a16989f9fc802eebb7307bdd89.svg#fontcustom") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "fontcustom";
    src: url("./fontcustom_f45e01a16989f9fc802eebb7307bdd89.svg#fontcustom") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-account-addresses:before,
.icon-account-manage-account:before,
.icon-account-reviews:before,
.icon-account-support:before,
.icon-account-track-order:before,
.icon-account-transactions:before,
.icon-collection-empty:before,
.icon-collection-filter-open:before,
.icon-collection-filterbar-candy:before,
.icon-collection-filterbar-candy-selected:before,
.icon-collection-filterbar-cool:before,
.icon-collection-filterbar-cool-selected:before,
.icon-collection-filterbar-dessert:before,
.icon-collection-filterbar-dessert-selected:before,
.icon-collection-filterbar-fruit:before,
.icon-collection-filterbar-fruit-selected:before,
.icon-collection-filterbar-other:before,
.icon-collection-filterbar-other-selected:before,
.icon-collection-item-review-star:before,
.icon-collection-item-review-star-empty:before,
.icon-collection-item-review-star-half:before,
.icon-delete:before,
.icon-edit:before,
.icon-facebook:before,
.icon-general-not-found:before,
.icon-info:before,
.icon-instagram:before,
.icon-mail:before,
.icon-navbar-account:before,
.icon-navbar-cart:before,
.icon-navbar-cross:before,
.icon-navbar-left-arrow:before,
.icon-navbar-menu:before,
.icon-navbar-promo:before,
.icon-navbar-reorder:before,
.icon-navbar-rewards:before,
.icon-navbar-right-arrow:before,
.icon-navbar-search:before,
.icon-navbar-shipping:before,
.icon-navbar-support:before,
.icon-no-orders:before,
.icon-not-found:before,
.icon-promo-percent:before,
.icon-promo-shipping:before,
.icon-promo-www:before,
.icon-under-construction:before {
  display: inline-block;
  font-family: "fontcustom";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-account-addresses:before { content: "\f100"; }
.icon-account-manage-account:before { content: "\f101"; }
.icon-account-reviews:before { content: "\f102"; }
.icon-account-support:before { content: "\f103"; }
.icon-account-track-order:before { content: "\f104"; }
.icon-account-transactions:before { content: "\f105"; }
.icon-collection-empty:before { content: "\f106"; }
.icon-collection-filter-open:before { content: "\f107"; }
.icon-collection-filterbar-candy:before { content: "\f108"; }
.icon-collection-filterbar-candy-selected:before { content: "\f109"; }
.icon-collection-filterbar-cool:before { content: "\f10a"; }
.icon-collection-filterbar-cool-selected:before { content: "\f10b"; }
.icon-collection-filterbar-dessert:before { content: "\f10c"; }
.icon-collection-filterbar-dessert-selected:before { content: "\f10d"; }
.icon-collection-filterbar-fruit:before { content: "\f10e"; }
.icon-collection-filterbar-fruit-selected:before { content: "\f10f"; }
.icon-collection-filterbar-other:before { content: "\f110"; }
.icon-collection-filterbar-other-selected:before { content: "\f111"; }
.icon-collection-item-review-star:before { content: "\f112"; }
.icon-collection-item-review-star-empty:before { content: "\f113"; }
.icon-collection-item-review-star-half:before { content: "\f114"; }
.icon-delete:before { content: "\f115"; }
.icon-edit:before { content: "\f116"; }
.icon-facebook:before { content: "\f117"; }
.icon-general-not-found:before { content: "\f118"; }
.icon-info:before { content: "\f119"; }
.icon-instagram:before { content: "\f11a"; }
.icon-mail:before { content: "\f11b"; }
.icon-navbar-account:before { content: "\f11c"; }
.icon-navbar-cart:before { content: "\f11d"; }
.icon-navbar-cross:before { content: "\f11e"; }
.icon-navbar-left-arrow:before { content: "\f11f"; }
.icon-navbar-menu:before { content: "\f120"; }
.icon-navbar-promo:before { content: "\f121"; }
.icon-navbar-reorder:before { content: "\f122"; }
.icon-navbar-rewards:before { content: "\f123"; }
.icon-navbar-right-arrow:before { content: "\f124"; }
.icon-navbar-search:before { content: "\f125"; }
.icon-navbar-shipping:before { content: "\f126"; }
.icon-navbar-support:before { content: "\f127"; }
.icon-no-orders:before { content: "\f128"; }
.icon-not-found:before { content: "\f129"; }
.icon-promo-percent:before { content: "\f12a"; }
.icon-promo-shipping:before { content: "\f12b"; }
.icon-promo-www:before { content: "\f12c"; }
.icon-under-construction:before { content: "\f12d"; }
